module.exports = [{
      plugin: require('/home/michael/PhpstormProjects/gatsby-mijnpresta/node_modules/gatsby-plugin-offline/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('/home/michael/PhpstormProjects/gatsby-mijnpresta/node_modules/gatsby-plugin-nprogress/gatsby-browser'),
      options: {"plugins":[],"color":"#141E30"},
    },{
      plugin: require('/home/michael/PhpstormProjects/gatsby-mijnpresta/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
